var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "xml-preview"
  }, [_vm.isLoaded ? _c('page-details', {
    attrs: {
      "category": _vm.category,
      "disableSaveToPdf": !_vm.tables.length,
      "title": _vm.title
    }
  }) : _vm._e(), _c('div', {
    staticClass: "xml-preview__container"
  }, [_c('div', {
    staticClass: "spinner-container"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  })], 1)]), _vm.isLoaded ? _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('specs', {
    attrs: {
      "items": _vm.specs
    }
  }), _vm._l(_vm.tables, function (table) {
    return _c('div', {
      key: table.id,
      class: ['border-table', table.type == 'simple' ? 'border-table--simple' : '']
    }, [_c('p', {
      staticClass: "border-table__header"
    }, [_vm._v(_vm._s(table.label))]), _c('div', {
      staticClass: "border-table__box"
    }, [_c('b-table', {
      attrs: {
        "fields": table.fields,
        "items": table.items
      }
    })], 1)]);
  }), _c('div', {
    staticClass: "xml-preview__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled red",
    attrs: {
      "disabled": !_vm.tables.length
    },
    on: {
      "click": function click($event) {
        return _vm.printPage();
      }
    }
  }, [_vm._v("Save to pdf ")])], 1)], 2)])]) : _vm._e()]), _vm.isLoaded ? _c('div', {
    staticClass: "xml-preview__footer"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-11 offset-xl-1 col-12"
  }, [_c('div', {
    staticClass: "xml-preview__footer-inner"
  }, [_vm._m(0), _c('router-link', {
    staticClass: "xml-preview__footer-logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo.png")
    }
  })])], 1)])])])]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "xml-preview__footer-info"
  }, [_vm._v("Report generated by "), _c('a', {
    attrs: {
      "href": "https://fundequate.com"
    }
  }, [_vm._v("Fundequate.com ")])]);
}]

export { render, staticRenderFns }